import { useMutation, useQueryClient } from 'react-query';

// Our Services
import BoxAPI from 'services/API/BoxAPI';

// Our Hooks
import useGetProfile from 'hooks/useGetProfile';

// Our Query Keys
import { SUCCESS_MESSAGE_DATA, ERROR_MESSAGE_DATA } from 'shared/query-keys';

function useMutateUploadBoxFile(folderId) {
	const queryClient = useQueryClient();
	const { data: profile } = useGetProfile();

	const { id, firstName, lastName } = profile;

	return useMutation(
		async (file) => {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('folderId', folderId);
			formData.append('userId', id);
			formData.append('advisorFirstName', firstName);
			formData.append('advisorLastName', lastName);

			const response = await BoxAPI.post('create/file', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
			return response.data;
		},
		{
			onSuccess: () => {
				queryClient.setQueryData(
					SUCCESS_MESSAGE_DATA,
					'Your file(s) has been succesfully uploaded'
				);
			},
			onError: () => {
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'Failure to upload. Please try again or contact support@sorafinance.com for ongoing issues'
				);
			}
		}
	);
}

export default useMutateUploadBoxFile;
