import { useMemo } from 'react';

// Our Utils
import { dataIsValid } from 'shared/utils';

function useDisableBalanceBasedOnSource(data, isSuccess) {
	const disableOutstandingBalance = useMemo(() => {
		if (isSuccess && data) {
			const isMfiData = dataIsValid(data?.mfiDataSource);

			if (isMfiData) return true;
		}

		return false;
	}, [data]);

	return disableOutstandingBalance;
}

export default useDisableBalanceBasedOnSource;
