import PropTypes from 'prop-types';
import { Alert as MuiAlert, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function StateAlert({ variant, isOpen, setIsOpen, message }) {
	// function called on close.
	const handleClose = () => {
		setIsOpen(false);
	};

	// Cleanup queries when exits
	const handleExited = () => {
		// 100 ms delay to clearing cache BC if immediate it will change SnackBar width BRIEFLY
		setTimeout(() => {
			handleClose();
		}, 100);
	};

	return (
		<Snackbar
			open={isOpen}
			autoHideDuration={5000}
			onClose={handleClose}
			TransitionProps={{ onExited: handleExited }}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
		>
			<MuiAlert
				severity={variant}
				variant="filled"
				action={
					<IconButton
						aria-label="close"
						color="inherit"
						sx={{ p: 0.5 }}
						onClick={handleClose}
					>
						<CloseIcon color="white" />
					</IconButton>
				}
			>
				{message}
			</MuiAlert>
		</Snackbar>
	);
}

StateAlert.propTypes = {
	variant: PropTypes.oneOf(['error', 'success', 'info']).isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	message: PropTypes.string.isRequired
};

export default StateAlert;
