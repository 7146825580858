// Valid TradeLine Types
export const ALL = 'ALL';
export const AUTO = 'AUTO';
export const BUSINESS = 'BUSINESS';
export const COLLECTION = 'COLLECTION';
export const CONSTRUCTION = 'CONSTRUCTION';
export const CREDITCARD = 'CREDITCARD';
export const COMMERCIAL = 'COMMERCIAL';
export const FARM = 'FARM';
export const HELOC = 'HELOC';
export const LOAN = 'LOAN';
export const LOT = 'LOT';
export const MORTGAGE = 'MORTGAGE';
export const PERSONAL = 'PERSONAL';
export const STUDENT = 'STUDENT';
export const CASHOUT = 'CASHOUT';
export const REVERSEMORTGAGE = 'REVERSE_MORTGAGE';
export const SBLOC = 'SBLOC';
export const SBLINE = 'SBLINE';

// Biz Loan Constants
export const PURPOSE_OF_BIZ_LOAN_ACQUIRING = 'PURPOSE_OF_BIZ_LOAN_ACQUIRING';
export const PURPOSE_OF_BIZ_LOAN_WORKING_CAPITAL =
	'PURPOSE_OF_BIZ_LOAN_WORKING_CAPITAL';
export const PURPOSE_OF_BIZ_LOAN_REFINANCING =
	'PURPOSE_OF_BIZ_LOAN_REFINANCING';
export const PURPOSE_OF_BIZ_LOAN_PURCHASING = 'PURPOSE_OF_BIZ_LOAN_PURCHASING';
export const PURPOSE_OF_BIZ_LOAN_PURCHASING_FURNITURE =
	'PURPOSE_OF_BIZ_LOAN_PURCHASING_FURNITURE';
export const PURPOSE_OF_BIZ_LOAN_CHANGE_OF_OWNERSHIP =
	'PURPOSE_OF_BIZ_LOAN_CHANGE_OF_OWNERSHIP';
export const PURPOSE_OF_BIZ_LOAN_MULTIPLE_PURPOSE =
	'PURPOSE_OF_BIZ_LOAN_MULTIPLE_PURPOSE';

export const COMMERCIAL_LOAN_PURPOSE_OPTIONS_MAPPING = {
	PURPOSE_OF_BIZ_LOAN_ACQUIRING:
		'Acquiring, refinancing or improving real estate and buildings',
	PURPOSE_OF_BIZ_LOAN_WORKING_CAPITAL: 'Short- and long-term working capital',
	PURPOSE_OF_BIZ_LOAN_REFINANCING: 'Refinancing current business debt',
	PURPOSE_OF_BIZ_LOAN_PURCHASING:
		'Purchasing and installation of machinery and equipment, including AI-related expenses',
	PURPOSE_OF_BIZ_LOAN_PURCHASING_FURNITURE:
		'Purchasing furniture, fixtures, and supplies',
	PURPOSE_OF_BIZ_LOAN_CHANGE_OF_OWNERSHIP:
		'Changes of ownership (complete or partial)',
	PURPOSE_OF_BIZ_LOAN_MULTIPLE_PURPOSE:
		'Multiple purpose loans, including any of the above',
	'Acquiring, refinancing or improving real estate and buildings':
		PURPOSE_OF_BIZ_LOAN_ACQUIRING,
	'Short- and long-term working capital': PURPOSE_OF_BIZ_LOAN_WORKING_CAPITAL,
	'Refinancing current business debt': PURPOSE_OF_BIZ_LOAN_REFINANCING,
	'Purchasing and installation of machinery and equipment, including AI-related expenses':
		PURPOSE_OF_BIZ_LOAN_PURCHASING,
	'Purchasing furniture, fixtures, and supplies':
		PURPOSE_OF_BIZ_LOAN_PURCHASING_FURNITURE,
	'Changes of ownership (complete or partial)':
		PURPOSE_OF_BIZ_LOAN_CHANGE_OF_OWNERSHIP,
	'Multiple purpose loans, including any of the above':
		PURPOSE_OF_BIZ_LOAN_MULTIPLE_PURPOSE
};

// Valid Offer Types
export const NEW_LOAN_OFFER_TYPE = 'NEW_LOAN';
export const REFINANCE_OFFER_TYPE = 'REFINANCE';
export const CASHOUT_OFFER_TYPE = 'CASHOUT';

// translated TradeLine Type used for display
export const MORTGAGE_TAB_TEXT = 'Home';

// Valid Onboard Update States
export const ASSETS_UPDATED = 'ASSETS_UPDATED';
export const INCOME_ACCOUNT_CONNECTED = 'INCOME_ACCOUNT_CONNECTED';
export const INCOME_UPDATED = 'INCOME_UPDATED';
export const LIABILITIES_UPDATED = 'LIABILITIES_UPDATED';
export const LIABILITY_ACCOUNT_CONNECTED = 'LIABILITY_ACCOUNT_CONNECTED';
export const REVIEW_COMPLETED = 'REVIEW_COMPLETED';
export const SIGNUP_COMPLETED = 'SIGNUP_COMPLETED';
export const TASKS_IN_PROGRESS_FLAGS = [
	SIGNUP_COMPLETED,
	LIABILITY_ACCOUNT_CONNECTED,
	LIABILITIES_UPDATED,
	INCOME_ACCOUNT_CONNECTED,
	INCOME_UPDATED,
	ASSETS_UPDATED
];

export const INITIAL_REQUEST = 'Initial request';
export const ADD_ADDITIONAL_INFORMATION = 'Add additional information';
export const UNDER_REVIEW = 'Under review';
export const IN_UNDERWRITING = 'In underwriting';
export const COMPLETED = 'Completed';
export const CANCELED = 'Canceled';
export const REJECTED = 'Rejected';

export const LOAN_REQUESTS_ADVISOR_PROGRESS_FLAGS = [
	INITIAL_REQUEST,
	ADD_ADDITIONAL_INFORMATION,
	UNDER_REVIEW,
	IN_UNDERWRITING,
	COMPLETED,
	CANCELED,
	REJECTED
];

// Invite status and Import statuses
export const INVITED = 'INVITED';
export const ONBOARDED = 'ONBOARDED';

// Task Table Default Options
export const DEFAULT_TASK_ACTION_OPTIONS = [
	'Complete onboard',
	'Invite to onboard'
];

// Loans Tasks page Table default options
export const LOAN_TASK_ACTION_OPTIONS = ['Contact Sora', 'Review Loan'];
// export const Unknown_Client_State = 'Unknown Client State' This is a possible item but it has not been clarified yet.

// Currently Accepted Offer Keys
export const ACCEPTED_OFFER_KEYS = [
	'closingCost',
	'interestRate',
	'monthlyPayUsd',
	'totalCashOutAmount',
	'totalCostOfDebt',
	'totalLoanAmount',
	'tenure',
	'lender',
	'soraScore',
	'cashOutPotential',
	'remainingMonth'
];

// App Constants
export const CREDIT_SCORE_OPTIONS = [
	{
		label: 'Excellent (720)+',
		value: 'EXCELLENT'
	},
	{
		label: 'Good (680-719)',
		value: 'GOOD'
	},
	{
		label: 'Fair (640-679)',
		value: 'FAIR'
	},
	{
		label: 'Poor (<639)',
		value: 'POOR'
	},
	{
		label: "I don't know",
		value: 'DONOTKNOW'
	}
];

export const CITIZEN_STATUS_OPTIONS = [
	'US Citizen',
	'Permanent Resident',
	'Non Citizen',
	'Other Citizenship'
];

export const EDUCATION_LEVEL_OPTIONS = [
	'Some High School',
	'High School Graduate',
	'Bachelors',
	'Masters',
	'Doctorate',
	'Professional',
	'Other Educational Degree'
];

export const EMPLOYMENT_STATUS_OPTIONS = [
	'Employed - salary',
	'Employed - hourly',
	'Self-Employed',
	'Contractor',
	'Retired',
	'Student',
	'Unemployed'
];

export const MORTGAGE_LOAN_TENURE_OPTIONS = [
	'10 year fixed',
	'15 year fixed',
	'30 year fixed',
	'3 ARM',
	'5 ARM',
	'7 ARM'
];

export const ENUM_FINANCIAL_GOAL_MAPPING = [
	{
		label: 'Reduce my monthly payments',
		value: 'LOW_EMI'
	},
	{
		label: 'Reduce total cost of debt',
		value: 'LOW_COD'
	},
	{
		label: 'Pay off my loans faster',
		value: 'EARLY_PAYOFF'
	},
	{
		label: 'Cash out',
		value: 'CASH_OUT'
	}
];

export const FINANCIAL_GOAL_OPTIONS = [
	'Reduce my monthly payments',
	'Reduce total cost of debt',
	'Pay off my loans faster',
	'Cash out'
];

export const LOAN_TENURE_OPTIONS = {
	Fixed: ['10-year', '15-year', '20-year', '30-year', '40-year'],
	ARM: ['3-year', '5-year', '7-year', '10-year']
};

export const MORTGAGE_LOAN_LENGTH_OPTIONS = [
	{
		label: '0-4 years',
		value: 'ZEROTOFOURYEARS'
	},
	{
		label: '5-7 years',
		value: 'FIVETOSEVENYEARS'
	},
	{
		label: '8-14 years',
		value: 'EIGHTTOFOURTEENYEARS'
	},
	{
		label: '15-29 years',
		value: 'FIFTEENTOTWENTYNINEYEARS'
	},
	{
		label: '30+ years',
		value: 'THIRTYPLUSYEARS'
	}
];

export const US_STATE_OPTIONS = [
	'AK',
	'AL',
	'AR',
	'AS',
	'AZ',
	'CA',
	'CO',
	'CT',
	'DC',
	'DE',
	'FL',
	'FM',
	'GA',
	'GU',
	'HI',
	'IA',
	'ID',
	'IL',
	'IN',
	'KS',
	'KY',
	'LA',
	'MA',
	'MD',
	'ME',
	'MH',
	'MI',
	'MN',
	'MO',
	'MP',
	'MS',
	'MT',
	'NC',
	'ND',
	'NE',
	'NH',
	'NJ',
	'NM',
	'NV',
	'NY',
	'OH',
	'OK',
	'OR',
	'PA',
	'PR',
	'PW',
	'RI',
	'SC',
	'SD',
	'TN',
	'TX',
	'UT',
	'VA',
	'VI',
	'VT',
	'WA',
	'WI',
	'WV',
	'WY'
];

export const VETERAN_STATUS_OPTIONS = ['Yes', 'No'];

export const TYPES_OF_HOME_PROPERTIES = [
	'Primary residence',
	'Secondary/Vacation residence',
	'Investment property',
	'Other'
];

export const DEFAULT_DECIMAL_ROUND = 2;

// Liability Constants
export const PRE_POPULATE_FIELDS_TO_SKIP = [
	'rateManuallyUpdated',
	'mfiDataSource'
];

export const DEGREE_TYPES = [
	'Some High School',
	'High School Graduate',
	'Bachelors',
	'Masters',
	'Doctorate',
	'Professional',
	'Other Educational Degree'
];
export const TYPES_OF_STUDENT_LOANS = ['Federal', 'Private'];

// Rules Engine MODES
export const FE_CHATGPT_ONLY = '0';
export const BE_NO_CHATGPT = '1';
export const BE_WITH_CHATGPT = '2';

// Roles
export const ADVISOR = 'ADVISOR';
export const ADMIN = 'SORA_ADMIN';
export const CLIENT = 'CLIENT';

// Offer Types
export const REFINANCE_OFFER = 'refinance';

// React Query Defaults
export const DEFAULT_STALE_TIME_FIVE_MINUTES = 1000 * 60 * 5;

// Local Constants
const PARTIAL_VISIBILITY_GUTTER = 30;

// Default Insights Carousel Break Points
export const DEFAULT_INSIGHTS_CAROUSEL_BREAK_POINTS = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 6000, min: 1921 },
		items: 4,
		partialVisibilityGutter: PARTIAL_VISIBILITY_GUTTER
	},
	desktop: {
		breakpoint: { max: 1920, min: 901 },
		items: 3,
		partialVisibilityGutter: PARTIAL_VISIBILITY_GUTTER
	},
	tablet: {
		breakpoint: { max: 900, min: 600 },
		items: 2,
		partialVisibilityGutter: PARTIAL_VISIBILITY_GUTTER
	},
	mobile: {
		breakpoint: { max: 599, min: 0 },
		items: 1,
		partialVisibilityGutter: PARTIAL_VISIBILITY_GUTTER
	}
};

// Default Insights Carousel Timer
export const DEFAULT_CAROUSEL_SPEED_EIGHT_SECONDS = 1000 * 8;

// Liability Confidence Level Mapping
export const LIABILITY_CONFIDENCE_LEVEL_MAPPING =
	process.env.REACT_APP_LIABILITY_CONFIDENCE_MAPPING;

// Onboarding Sources
export const ADVISOR_INTEGRATION = 'ADVISOR_INTEGRATION';
export const ADVISOR_MANUAL = 'ADVISOR_MANUAL';

// Advisor Loan Request status'
// export const ADVISOR_LOAN_REQUEST_STATUS_SEARCH_RATES =
export const ADVISOR_LOAN_REQUEST_STATUS_CONSTANTS = {
	PENDING_INFO: 'PENDING_INFO',
	FA_SEARCH_RATES: 'FA_SEARCH_RATES',
	SELECT_RATE: 'SELECT_RATE',
	UNDER_REVIEW: 'UNDER_REVIEW',
	PROCESSING: 'PROCESSING',
	FA_COMPLETED: 'FA_COMPLETED',
	FA_CANCELED: 'FA_CANCELLED',
	FA_REJECTED: 'FA_REJECTED',
	APPROVE_REQ: 'APPROVE_REQ',
	SEARCH_RATES: 'SEARCH_RATES'
};

// Assumption Options
export const APPLY_CAPITAL_GAINS_TAX_SIMPLE = [
	'Apply to investment accounts',
	'Do not apply to investment accounts'
];

export const APPLY_CAPITAL_GAINS_TAX_DETAILED = [
	'Apply to non-brokerage',
	'Do not apply to non-brokerage'
];

// Ignore these address fields when prepopulating
export const ADDRESS_FIELDS_TO_IGNORE = new Set([
	'addressId',
	'marketValue',
	'assedValue',
	'dwellingType'
]);

// Dts related
export const DTS_COMPLETED = 'DTS_COMPLETED';
export const DTS_NOT_STARTED = 'DTS_NOT_STARTED';
export const DTS_IN_PROGRESS = 'DTS_IN_PROGRESS';

// Onboarding Source Types
export const ONBOARDING_SOURCE_TYPE_SELF = 'SELF';
