import { useEffect, useState, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';

// Our Components
import Dropdown from 'components/Dropdown/Dropdown';
import GetFullAddressPartialForm from 'components/Forms/addressRelated/GetFullAddressPartialForm';
import LiabilityFormHeading from 'components/Client/Onboarding/LiabilityFormHeading';
import LiabilityDynamicButton from 'components/Client/Onboarding/LiabilityDynamicButton';
import Loader from 'components/Loader';
import OutstandingBalanceInput from 'components/Client/Onboarding/OutstandingBalanceInput';
import RateInput from 'components/Input/RateInput';
import MonthlyPaymentInput from 'components/Client/Onboarding/MonthlyPaymentInput';
import StandardDatePicker from 'components/DatePicker/StandardDatePicker';
import TextInput from 'components/Input/TextInput';

// Input Types
import { TEXT } from 'components/Input/Types';

// our hooks
import useGetUserMortgageLiability from 'hooks/clientOnboarding/useGetUserMortgageLiability';
import useMutateSaveMortgageLiability from 'hooks/clientOnboarding/mutations/useMutateSaveMortgageLiability';
import useDisableBalanceBasedOnSource from 'hooks/liability/util/useDisableBalanceBasedOnSource';

// Query Keys
import { USERPROFILEDATA } from 'shared/query-keys';

// Utils
import { isSubmissionReady, dataIsValid, roundToDecimal } from 'shared/utils';
import getDefaultApproxPayoffDate from 'shared/utils/clientOnboarding/getDefaultApproxPayoffDate';
import shouldUpdateRateManuallyUpdated from 'shared/utils/events/shouldUpdateRateManuallyUpdated';
import dateToIsoFormat from 'shared/utils/clientOnboarding/dateToIsoFormat';
import PROPERTY_TYPES_TO_ENUM_MAPPING from 'shared/utils/clientOnboarding/propertyTypeToEnumMapping';
import normalizeLoanType from 'shared/utils/clientOnboarding/normalizeLoanType';

// Constants
import {
	HELOC,
	MORTGAGE,
	TYPES_OF_HOME_PROPERTIES,
	PRE_POPULATE_FIELDS_TO_SKIP
} from 'shared/constants';

const HOME_PROPERTIES_TO_SKIP = new Set([
	'id',
	'assedValue',
	'currency',
	'marketValue',
	'mfiDataSource'
]);

function HelocLiability() {
	const navigate = useNavigate();
	const saveMortgage = useMutateSaveMortgageLiability();
	const { isLoading, isSuccess, data } = useGetUserMortgageLiability();
	const { tradeLineId } = useParams();

	const { isLoading: isMutationLoading } = saveMortgage;
	const queryClient = useQueryClient();

	const [lender, setLender] = useState('');
	const [outstandingBalance, setOutstandingBalance] = useState('');
	const [rate, setRate] = useState('');
	const [monthlyPayment, setMonthlyPayment] = useState(0);
	const [approxPayoffDate, setApproxPayoffDate] = useState(
		getDefaultApproxPayoffDate(MORTGAGE)
	);
	const [propertyType, setPropertyType] = useState('Primary'); // this refers to Loan Type field
	const [disableAutoComplete, setDisableAutoComplete] = useState(false);

	// Address Related
	const [addressLine1, setAddressLine1] = useState('');
	const [addressLine2, setAddressLine2] = useState('');
	const [city, setCity] = useState('');
	const [state, setState] = useState('');
	const [zipCode, setZipCode] = useState('');

	const didRateChange = useMemo(() => {
		// if data is empty false
		if (!dataIsValid(data)) return false;
		// if interestRate is empty false.
		if (!dataIsValid(data?.interestRate)) return false;
		// if rate is empty false.
		if (rate === '') return false;
		// compare NOW
		return +rate !== data.interestRate;
	}, [rate, data]);

	const disableOutstandingBalance = useDisableBalanceBasedOnSource(
		data,
		isSuccess
	);

	useEffect(() => {
		if (isSuccess) {
			// This maps the fieldName in the data response to the corresponding state fnc that updates that state
			const WANTED_KEY_SET_MAPPING = {
				lender: setLender,
				outstandingBalance: setOutstandingBalance,
				interestRate: setRate,
				monthlyPay: setMonthlyPayment,
				expectedPayOffDate: setApproxPayoffDate,
				propertyType: setPropertyType,
				addressLine1: setAddressLine1,
				addressLine2: setAddressLine2,
				state: setState,
				zipcode: setZipCode,
				city: setCity
			};

			const helocLiabilityFields = Object.keys(data);

			try {
				helocLiabilityFields.forEach((helocLiabilityField) => {
					if (
						PRE_POPULATE_FIELDS_TO_SKIP.concat(
							'tenureMonth'
						).includes(helocLiabilityField)
					)
						return;
					const currentData = data[helocLiabilityField];
					const setUpdater =
						WANTED_KEY_SET_MAPPING[helocLiabilityField];
					if (
						helocLiabilityField === 'interestRate' ||
						helocLiabilityField === 'monthlyPay' ||
						helocLiabilityField === 'outstandingBalance'
					) {
						// Here the values are ints we need to convert them to strings
						setUpdater(`${currentData}`);
						return;
					}
					if (
						helocLiabilityField === 'escrow' ||
						helocLiabilityField === 'financialGoal' ||
						helocLiabilityField === 'loanType' ||
						helocLiabilityField === 'tenureMonth'
					) {
						return;
					}
					if (helocLiabilityField === 'clientHome') {
						// data in this field is nested so we gotta iterate over that.
						const mortgageSpecificFields = Object.keys(currentData);

						mortgageSpecificFields.forEach(
							(mortgageSpecificField) => {
								// assedValue is not used so skip it.
								if (
									HOME_PROPERTIES_TO_SKIP.has(
										mortgageSpecificField
									)
								)
									return;

								const mortgageSpecificUpdater =
									WANTED_KEY_SET_MAPPING[
										mortgageSpecificField
									];
								const mortgageSpecificData =
									currentData[mortgageSpecificField];

								// This data is may or may not be null
								if (!dataIsValid(mortgageSpecificData)) return;
								if (mortgageSpecificField === 'propertyType') {
									const formattedPropertyType =
										normalizeLoanType(mortgageSpecificData);
									mortgageSpecificUpdater(
										formattedPropertyType
									);
									return;
								}
								mortgageSpecificUpdater(mortgageSpecificData);
							}
						);

						return;
					}

					setUpdater(currentData);
				});
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e);
			}
		}
	}, [data]);

	useEffect(
		() => () => {
			setAddressLine1('');
			setAddressLine2('');
			setCity('');
			setState('');
			setZipCode('');
		},
		[tradeLineId]
	);

	const requiredFormValues = [
		lender,
		outstandingBalance,
		rate,
		monthlyPayment,
		approxPayoffDate,
		propertyType,
		addressLine1,
		city,
		state,
		zipCode
	];

	const isFormReady = useMemo(
		() => isSubmissionReady(requiredFormValues),
		requiredFormValues
	);

	const handleApproxPayoffDateChange = (value) => {
		const isValueEmpty = value === '';
		if (isValueEmpty) {
			setApproxPayoffDate('');
			return;
		}
		setApproxPayoffDate(value);
	};

	const paramsFromURL = useParams();
	const clientId = paramsFromURL?.clientId;

	const profileData = queryClient.getQueryData(USERPROFILEDATA);
	const isClient = profileData?.role === 'CLIENT';

	const submitLiabilityData = (route) => {
		const formattedRate = roundToDecimal(+rate);
		const formattedApproxPayOffDate = dateToIsoFormat(approxPayoffDate);
		const formattedPropertyType =
			PROPERTY_TYPES_TO_ENUM_MAPPING(propertyType);

		const HelocLiabilityData = {
			clientId,
			lender,
			outstandingBalance,
			approxPayoffDate: formattedApproxPayOffDate,
			rate: formattedRate,
			monthlyPayment,
			tradelineType: HELOC,
			propertyType: formattedPropertyType,
			addressLine1,
			addressLine2,
			city,
			state,
			zipCode
		};

		HelocLiabilityData.rateManuallyUpdated =
			shouldUpdateRateManuallyUpdated(
				data?.rateManuallyUpdated,
				didRateChange
			);
		saveMortgage.mutate(HelocLiabilityData, {
			onSuccess: () => {
				if (!isClient) {
					const advisorRoute = `/sora-wallet-overview/advisor/${clientId}`;
					navigate(advisorRoute);
				} else {
					navigate(route);
				}
			}
		});
	};

	if (isLoading) {
		return (
			<>
				<Helmet>
					<title>Heloc</title>
				</Helmet>
				<LiabilityFormHeading headingText="Heloc" />
				<Loader
					size={60}
					boxSX={{ alignItems: 'center', marginTop: 15 }}
				/>
			</>
		);
	}
	return (
		<>
			<Helmet>
				<title>Heloc</title>
			</Helmet>

			<LiabilityFormHeading headingText="Heloc" />

			<Box component="form" noValidate autoComplete="off">
				<Grid container columnSpacing={2} marginTop={4}>
					<Grid item xs={12} md={6} sx={{ height: 120 }}>
						<TextInput
							type={TEXT}
							label="Lender"
							subLabel="Enter the name of your lender"
							value={lender}
							onChange={setLender}
						/>
					</Grid>

					<Grid item xs={12} md={6} sx={{ height: 120 }}>
						<OutstandingBalanceInput
							outstandingBalance={outstandingBalance}
							setOutstandingBalance={setOutstandingBalance}
							disabled={disableOutstandingBalance}
						/>
					</Grid>

					<Grid item xs={12} md={6} sx={{ height: 120 }}>
						<RateInput rate={rate} setRate={setRate} />
					</Grid>

					<Grid
						item
						xs={12}
						md={6}
						sx={{ height: 120, marginBottom: 2 }}
					>
						<MonthlyPaymentInput
							monthlyPayment={monthlyPayment}
							outstandingBalance={outstandingBalance}
							setMonthlyPayment={setMonthlyPayment}
						/>
					</Grid>

					<Grid item xs={12} md={6} sx={{ height: 120 }}>
						<StandardDatePicker
							label="Expected Payoff Date"
							helperText="Enter the approximate payoff date"
							onChange={handleApproxPayoffDateChange}
							value={approxPayoffDate}
							error={approxPayoffDate === ''}
						/>
					</Grid>
					<Grid item xs={12} md={6} sx={{ height: 120 }}>
						<Dropdown
							items={TYPES_OF_HOME_PROPERTIES}
							selected={propertyType}
							onChange={setPropertyType}
							variant="outlined"
							label="Type of property"
						/>
					</Grid>

					<GetFullAddressPartialForm
						addressLine1={addressLine1}
						addressLine2={addressLine2}
						city={city}
						state={state}
						zipCode={zipCode}
						setAddressLine1={setAddressLine1}
						setAddressLine2={setAddressLine2}
						setCity={setCity}
						setState={setState}
						setZipCode={setZipCode}
						disableAutoComplete={disableAutoComplete}
						setDisableAutoComplete={setDisableAutoComplete}
					/>

					<Grid item xs={12} sx={{ marginTop: 4 }}>
						<LiabilityDynamicButton
							disabled={!isFormReady}
							isMutationLoading={isMutationLoading}
							onClick={submitLiabilityData}
						/>
					</Grid>
				</Grid>
			</Box>
		</>
	);
}

export default HelocLiability;
